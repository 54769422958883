// src/components/Hero.js

import React from "react";
import img1 from '../../assets/home_intro_img1.jpg';
import img2 from '../../assets/home_intro_img2.jpg';
import img3 from '../../assets/home_intro_img3.jpg';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Hero.css';

function Hero() {
    return (
        <section className="container mt-5">
            <div className="row justify-content-center align-items-center">
                {/* Main Image */}
                <div className="col-md-6 text-center">
                    <img className="img-fluid" src={img1} alt="Main" />
                </div>
                {/* Side Images */}
                <div className="col-md-5 d-flex mt-2 flex-column justify-content-center">
                    <img className="img-fluid mb-2 pt-2" src={img2} alt="Side Image 1" />
                    <img className="img-fluid pb-2" src={img3} alt="Side Image 2" />
                </div>
            </div>
            <div>
                <div className=" col-xxl-7 text-center">
                    <h2 className="text-bolder fs-2 ">A Hut To Call Home</h2>
                    <p className="text-secondary fs-5">You’ve got the drive to be a franchisee, and we’ve got the tools to help you take charge. Let’s get you <br /> started on the path to your very own Pizza Hut.</p>
                </div>



            </div>
        </section>
    );
}

export default Hero;
