import React from "react";
import img from '../../assets/partision.png'
import img2 from '../../assets/home_about.jpg'

function Aboutus() {
    return (
        <div className="container">
            <div className="row">
                <div className="col-12 mb-5">
                    <img src={img} alt="Image" className="img-fluid w-100" />
                </div>
            </div>
            <div className="row d-flex">
                <div className="col-md-6 col-12">
                    <img src={img2} alt="Small Image" className="img-fluid" />
                </div>
                <div className="col-md-6 col-12 d-flex flex-column ">
                    <h2 className="mt-3 mt-md-0 text-start">A Little About Us</h2>
                    <p className="text-start">
                        From a single 500-square-foot pizza place in Kansas to the Pizza Hut you know today, we’ve been serving up small-town passion, big-minded innovation, and lots of great pizza for over 60 years.
                        Click below to learn more about how we got here, as well as the work we’re putting in toward building a better future.
                    </p>
                    <div className="d-flex">
                        <button className="btn btn-danger">Our Story</button>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default Aboutus;