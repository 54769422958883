import React from "react";
import img from '../../assets/line_home.png'
import img1 from '../../assets/home_started_storefront.jpg'
import img2 from '../../assets/home_started_pizza.jpg'

function HowToStart() {
    return (
        <div className="container">
            
            <div className="row mb-4">
                <div className="col-12">
                    <img src={img} alt="" className="img-fluid w-100" />
                </div>
            </div>

            
            <div className="row mb-4">
                <div className="col-12">
                    <h2>How to Get Started</h2>
                    <p>
                        Ready to join the family? Check out our restaurant opportunities to see which one may be right for you. After that,<br /> we’ll begin our Qualification and Approval process.
                    </p>
                </div>
            </div>

            
            <div className="row">
                
                <div className="col-md-6 col-12 mb-3 d-flex flex-column">
                    <img src={img1} alt="Image" className="img-fluid" />
                    <div className="d-flex mt-3">
                        <button className="btn btn-danger ">Our Restaurants</button>
                    </div>
                </div>

                
                <div className="col-md-6 col-12 d-flex flex-column">
                    <img src={img2} alt="Image" className="img-fluid" />
                    <div className="d-flex mt-3">
                        <button className="btn btn-danger ">Our Restaurants</button>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default HowToStart;