// src/components/UserForm.js

import React, { useState } from 'react';
import axios from 'axios';
import '../UserForm/UserForm.css'

const UserForm = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    phoneNumber: '',
    email: '',
    city: '',
    zipCode: '',
    netWorth: ''
  });

  const clearForm = () => {
    setFormData({
      fullName: '',
      phoneNumber: '',
      email: '',
      city: '',
      zipCode: '',
      netWorth: ''
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('https://api.sendinblue.com/v3/smtp/email', {
        sender: { email: 'pizzahutfranchises@gmail.com' }, // Replace with your email
        to: [{ email: 'pizzahutfranchises@gmail.com' }], // Replace with your email
        subject: 'New Form Submission',
        htmlContent: `
          <h1>Form Submission Details</h1>
          <p>Full Name: ${formData.fullName}</p>
          <p>Phone Number: ${formData.phoneNumber}</p>
          <p>Email: ${formData.email}</p>
          <p>City: ${formData.city}</p>
          <p>Zip Code: ${formData.zipCode}</p>
          <p>Net Worth: ${formData.netWorth}</p>
        `
      }, {
        headers: {
          'Content-Type': 'application/json',
          'api-key': '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' // Replace with your API key
        }
      });

      console.log('Email sent successfully:', response.data);

      alert("Form submitted successfully!");

      // Clear form fields
      clearForm();

    } catch (error) {
      console.error('Error sending email:', error);
      alert(error);
    }
  };

  return (
    <div className="container mt-5">
      <form onSubmit={handleSubmit}>
        <div className="mainDiv mb-3">
          <label htmlFor="fullName" className="form-label">Full Name</label>
          <input
            type="text"
            className="form-control border border-secondary"
            id="fullName"
            name="fullName"
            value={formData.fullName}
            onChange={handleChange}
            required
          />
        </div>

        <div className="mainDiv mb-3">
          <label htmlFor="phoneNumber" className="form-label">Phone Number</label>
          <input
            type="tel"
            className="form-control border border-secondary"
            id="phoneNumber"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
            required
          />
        </div>

        <div className="mainDiv mb-3">
          <label htmlFor="email" className="form-label">Email Address</label>
          <input
            type="email"
            className="form-control border border-secondary"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>

        <div className="mainDiv mb-3">
          <label htmlFor="city" className="form-label">City</label>
          <input
            type="text"
            className="form-control border border-secondary"
            id="city"
            name="city"
            value={formData.city}
            onChange={handleChange}
            required
          />
        </div>

        <div className="mainDiv mb-3">
          <label htmlFor="zipCode" className="form-label">Zip Code</label>
          <input
            type="text"
            className="form-control border border-secondary"
            id="zipCode"
            name="zipCode"
            value={formData.zipCode}
            onChange={handleChange}
            required
          />
        </div>

        <div className="mainDiv mb-3">
          <label htmlFor="netWorth" className="form-label">Net Worth</label>
          <select
            className="form-select border border-secondary"
            id="netWorth"
            name="netWorth"
            value={formData.netWorth}
            onChange={handleChange}
            required
          >
            <option value="">Net Worth</option>
            <option value="2 lakh to 2.5 lakh">2 lakh to 2.5 lakh</option>
            <option value="5 lakh to 7 lakh">5 lakh to 7 lakh</option>
            <option value="10 lakh to 12 lakh">10 lakh to 12 lakh</option>
          </select>
        </div>

        <button type="submit" className="sendButton btn btn-danger">Send</button>
      </form>
    </div>
  );
};

export default UserForm;
