import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from './components/Navbar/Navbar';
import UserForm from './components/UserForm/userForm';
import Aboutus from './components/Pages/Aboutus';
import Hero from './components/Hero/Hero';
import Journey from './components/Pages/Journey';
import BuyTheNumber from './components/Pages/BuyTheNumber';
import HowToStart from './components/Pages/HowToStart';
import Footer from './components/Footer/Footer';


function App() {
  return (
    <div className="App">
      <Navbar/>
      <UserForm/>
      <Hero/>
      <Aboutus/>
      <Journey/>
      <BuyTheNumber/>
      <HowToStart/>
      <Footer/>
    </div>
  );
}

export default App;
