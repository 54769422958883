import React from "react";
import img from '../../assets/byTheNumber.png'
import img2 from '../../assets/bythenum.png'

function BuyTheNumber() {
    return (
        <div className="container d-flex flex-column">
            <div className="">
                <div className=" ">
                    <img src={img2} alt="" className="img-fluid w-100 mt-5" />
                </div>
                <div className="">
                    <img src={img} alt="" className="img-fluid w-100 mt-5" />
                </div>
            </div>
        </div>

    );
};

export default BuyTheNumber;