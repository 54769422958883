import React from "react";
import img from '../../assets/logo_header_min.png';
import '../Footer/Footer.css'

function Footer() {
    return (
        <footer className="bg-secondary text-light pt-4 mt-3">
            <div className="container">
                <div className="row">

                    {/* Logo and description */}
                    <div className=" d-flex align-items-start col-md-4 col-12 mb-4">
                        <img src={img} alt="Logo" className="imageCs img-fluid mb-3"  />
                        <p className="custom-small-text text-start">
                            This website is not a franchise offering and it is not directed to the residents of any particular jurisdiction. 
                            No offer or sale of a restaurant franchise will be made except by a franchise disclosure document first filed 
                            and registered with applicable state authorities. Any information collected on this is subject to our Privacy Policy. 
                            
                        </p>
                    </div>

                    {/* Links Section */}
                    <div className="col-md-8 col-12">
                        <div className="row">
                            <div className="col-md-4 col-6 mb-4">
                                <h6 className="hoverColor"  >Our Story</h6
                                >
                            </div>
                            <div className="col-md-4 col-6 mb-4">
                                <h6 className="hoverColor" 
                                >Our Family</h6
                                >
                            </div>
                            <div className="col-md-4 col-6 mb-4">
                                <h6 className="hoverColor" 
                                >Our Restaurants</h6
                                >
                            </div>
                            <div className="col-md-4 col-6 mb-4">
                                <h6 className="hoverColor" 
                                >Investment</h6
                                >
                            </div>
                            <div className="col-md-4 col-6 mb-4">
                                <h6 className="hoverColor" 
                                >FAQs</h6
                                >
                            </div>
                            <div className="col-md-4 col-6 mb-4">
                                <h6 className="hoverColor" >Site Submittal</h6>
                            </div>
                        </div>
                    </div>

                </div>

                {/* Join Our Family Button */}
                <div className="row">
                    <div className=" d-flex col-12 text-center mb-4">
                        <button className="btn btn-danger">Join Our Family</button>
                    </div>
                </div>

                {/* Footer Bottom */}
                <div className="row">
                    <div className="col-12 text-center mt-4">
                        <p className="mb-0">© 2021 Pizza Hut, LLC. All Rights Reserved.</p>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
