import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import img from '../../assets/logo_header_min.png'
import '../Navbar/Navbar.css'

function Navbar() {
    return (
        <nav className="navbar  navbar-expand-lg p-4 navbar-light bg-white">
            <div className="container-fluid">
                <a className="navbar-brand" href="#"><img src={img} alt="logo" style={{ width: '8rem', height: '5rem' }} /></a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <a className="hoverColor nav-link active fs-5 fw-bolder"  aria-current="page" href="#">Our Story</a>
                        </li>
                        <li className="nav-item">
                            <a className="hoverColor nav-link fs-5 fw-bolder " href="#">Our Family</a>
                        </li>
                        <li className="nav-item">
                            <a className=" hoverColor nav-link fs-5 fw-bolder" href="#">Our Restaurants</a>
                        </li>
                        <li className="nav-item">
                            <a className=" hoverColor nav-link  fs-5 fw-bolder" href="#" tabindex="-1" aria-disabled="true">FAQs</a>
                        </li>
                        <li className="nav-item">
                            <a className=" hoverColor nav-link fs-5 fw-bolder " href="#" tabindex="-1" aria-disabled="true">Investment</a>
                        </li>
                        
                        <li className="nav-item">
                            <a className="hoverColor nav-link fs-5 fw-bolder" href="#" tabindex="-1" aria-disabled="true">Site Submittal</a>
                        </li>


                    </ul>
                    <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
                        <button className="btn btn-danger fs-5 fw-bold p-2" type="button">
                            Join Our Family
                        </button>
                    </div>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;
