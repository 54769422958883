import React from "react";
import img from '../../assets/partision1.png'
import img2 from '../../assets/home_journey.jpg'

function Journey() {
    return (
        <div className="container">
            <div className="row">
                <div className="col-12 mb-5">
                    <img src={img} alt="Image" className="img-fluid w-100" />
                </div>
            </div>
            <div className="row d-flex">
                <div className="col-md-6 col-12 d-flex flex-column ">
                    <h2 className="mt-3 mt-md-0 text-start">Your Journey</h2>
                    <p className="text-start">
                    At Pizza Hut, you’re in the business for yourself, but never by yourself. Because you’re not just a franchisee—you’re a part of the family.<br/>
                    <br/>

Click below to learn more about what makes us such a fun, rewarding place to work.
                    </p>
                    <div className="d-flex">
                        <button className="btn btn-danger mb-2 ">Our Family</button>
                    </div>
                </div>
                <div className="col-md-6 col-12">
                    <img src={img2} alt="Small Image" className="img-fluid" />
                </div>
            </div>
        </div>
    );
};

export default Journey;